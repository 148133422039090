import React, { createContext, useState, useContext, useEffect } from "react";
import WPClass from "./tools/WPClass";
import jsonMapWPData from "./json/map.json";

interface SocialMessage {
  link: string;
  name: string;
  class: string;
}
interface MenuMessage {
  url: string;
  title: string;
  attr_title: string;
  classes: any;
  target: string;
}

interface DataState {
  legal: "";
  header: MenuMessage[];
  footer: MenuMessage[];
  social: SocialMessage[];
  esrb: any;
  home: any;
  news: any;
  isReady: boolean;
}

interface LanguageContextType {
  language: string;
  languagePath: string;
  translate: DataState;
  setLanguage: (lang: string) => void;
  setLanguagePath: (lang: string) => void;
}

interface CommonData {
  [key: string]: {
    header: string;
    footer: string;
    options: string;
  };
}

const LanguageContext = createContext<LanguageContextType | undefined>(
  undefined
);
const endpoints: CommonData = jsonMapWPData;

interface LanguageProviderProps {
  children: React.ReactNode;
}

export const LanguageProvider: React.FC<LanguageProviderProps> = ({
  children,
}) => {
  const [language, setLanguage] = useState<string>("en");
  const [languagePath, setLanguagePath] = useState<string>("/");
  const [translate, setData] = useState<DataState>({
    header: [],
    footer: [],
    social: [],
    esrb: "",
    legal: "",
    home: {},
    news: {},
    isReady: false,
  });

  useEffect(() => {
    const storedData = sessionStorage.getItem(`content_${language}`);
    if (storedData) {
      setData(JSON.parse(storedData));
    } else {
      // Call the makeRequest method
      const wpInstance = new WPClass();
      wpInstance
        .makeRequestPromise(endpoints[language])
        .then((responseData) => {
          // Update state with the response data
          const respondsObj = {
            header: responseData.header,
            footer: responseData.footer,
            social: responseData.options.social,
            esrb: responseData.options.esrb.url,
            legal: responseData.options.legal,
            home: responseData.home,
            news: responseData.news,
            isReady: true,
          };
          setData(respondsObj);
          sessionStorage.setItem(
            `content_${language}`,
            JSON.stringify(respondsObj)
          );
        })
        .catch((error) => {
          // Handle any errors
          console.error(error);
        });
    }
  }, [language]);

  return (
    <LanguageContext.Provider
      value={{
        language,
        languagePath,
        translate,
        setLanguage,
        setLanguagePath,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error("useLanguage must be used within a LanguageProvider");
  }
  return context;
};
