import { useEffect, useRef, useState } from "react";
import imagesLoaded from "imagesloaded";
import Modal from "react-bootstrap/Modal";
import { Link, useLocation } from "react-router-dom";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Parallax from "parallax-js";
import GLightbox from "glightbox";
import { Navigation } from "swiper/modules";
import { Swiper as SwiperComponent, SwiperSlide } from "swiper/react";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { PreLoader } from "../components/PreLoader";
import SvgMoreBtn from "../components/SvgMoreBtn";
import { sanitizeToSlug, ScrollToView, wordTrim } from "../tools/Helpers";
import ScrollAnimation from "../tools/ScrollAnimation";
import { useLanguage } from "../LanguageContext";

import "../styles//Home.scss";

import FloatingLeftLeaves from "../assets/tots_leaves_left.png";
import FloatingRightLeaves from "../assets/tots_leaves_right.png";
import FloatingPapers from "../assets/tots_floating_papers.png";
import FloatingPapers2 from "../assets/tots_floating_paper2.png";

import SceneBkg from "../assets/tots_scene_bkg.png";
import SceneBkgMobile from "../assets/tots_scene_bkg2.png";
import SceneSky from "../assets/tots_scene_sky.jpg";
import SceneHighlight from "../assets/tots_scene_highlight.png";
import SceneParticles from "../assets/TOTS_Website_Yellow_Particles.png";
import SceneFloating from "../assets/tots_scene_floating.png";

import TrailerFrame from "../assets/TOTS_Website_Frame_Trailer.png";
import TrailerFrameLong from "../assets/TOTS_Website_Frame_Trailer_Long.png";
import AboutFrame from "../assets/TOTS_Website_Frame_About.png";
import GalleryFrameTop from "../assets/TOTS_Website_Frame_Gallery_top.png";
import GalleryFrameBottom from "../assets/TOTS_Website_Frame_Gallery_bottom.png";
import NewsFrame from "../assets/TOTS_Website_Frame_News.png";
import NewsFrameMobile from "../assets/TOTS_Website_Frame_News_mobile.png";

import TrailerChar from "../assets/tots_trailer_char.png";
import AboutSmPaperFly from "../assets/tots_small_paper.png";
import AboutMdPaperFly from "../assets/tots_med_paper.png";
import AboutFlowers from "../assets/tots_flowers.png";
import AboutChar2 from "../assets/tots_about_char2.png";
import AboutImage from "../assets/TOTS_about_image.jpg";

import GalleryPaperFly from "../assets/tots_large_paper.png";
import GalleryLargeLeaf from "../assets/tots_large_leaf.png";
import GallerySmallLeaf from "../assets/tots_sm_leaf.png";
import GalleryChar1 from "../assets/tots_character_gallery.png";

import NewsFish1 from "../assets/tots_right_fish.png";
import NewsFish2 from "../assets/tots_left_fish.png";

import CommunityChar1 from "../assets/tots_character_signup_2.png";
import CommunityChar2 from "../assets/tots_character_signup.png";

import MoreArrow from "../assets/more_arrow.svg";

import BrandLogo from "../assets/TOTS_brand_logo.png";
import GalleryArrows from "../assets/tots_direction_arrows.png";
import TrailerPlaceholder from "../assets/TOTS_Gallery_TrailerVideo.jpg";

const HeroTOTS = require("../assets/TOTS_LandingPage_bleed.mp4");

gsap.registerPlugin(ScrollTrigger);

interface SocialMessage {
  link: string;
  name: string;
  class: string;
}
interface StoreFrontMessage {
  link: string;
  name: string;
  image: {
    url: string;
  };
}
interface MenuMessage {
  title: string;
  url: string;
  attr_title: string;
  classes: any;
  target: string;
}
interface PostState {
  message: any;
}

const HomePage: React.FC = () => {
  const { pathname } = useLocation();
  const InitEffect = useRef(false);
  const [autoPlay, setAutoPlay] = useState<boolean>(true);
  const [showVideo, setShowVideo] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [post, setPost] = useState<PostState>({ message: {} });
  const videoRef = useRef<HTMLIFrameElement>(null);
  const { language, translate, languagePath } = useLanguage();
  const LongTextHeader = ["fr", "de", "it", "pl", "tr", "es", "pt", "ja"];
  const headerProps: {
    header: MenuMessage[];
    social: SocialMessage[];
    isReady: boolean;
  } = {
    header: translate.header,
    social: translate.social,
    isReady: translate.isReady,
  };

  const footerProps: {
    footer: MenuMessage[];
    social: SocialMessage[];
    esrb: "";
    legal: "";
    isReady: boolean;
  } = {
    footer: translate.footer,
    social: translate.social,
    esrb: translate.esrb,
    legal: translate.legal,
    isReady: translate.isReady,
  };

  const handlePayBtn = () => {
    setShowVideo(true);
  };

  const handleOpenModal = (id: number) => {
    setModalVisible(true);
    setPost({ message: translate.news[id] });
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setPost({ message: {} });
  };

  useEffect(() => {
    const ScrollController = new ScrollAnimation();
    const Init = () => {
      const videoHero = document.getElementById(
        "hero-video"
      ) as HTMLVideoElement;
      const startPlayPromise = videoHero.play();
      if (startPlayPromise !== undefined) {
        startPlayPromise.catch(() => {
          setAutoPlay(false);
        });
      }
      const imgLoad = imagesLoaded("#root", { background: true });
      imgLoad.on("done", () => {
        // Handle Inview Area
        ScrollController.ScrollAnim({
          element: "#app-nav",
          duration: "100%",
          trigger: 0.7,
          toggle: true,
        }); // header
        ScrollController.ScrollAnim({
          element: "#hero",
          duration: "100%",
          trigger: 0.7,
          toggle: true,
        }); // hero
        ScrollController.ScrollAnim({
          element: "#watch-trailer",
          duration: "100%",
          trigger: 0.7,
          toggle: true,
        }); // about
        ScrollController.ScrollAnim({
          element: "#about",
          duration: "100%",
          trigger: 0.7,
          toggle: true,
        }); // about
        ScrollController.ScrollAnim({
          element: "#gallery",
          duration: "100%",
          trigger: 0.7,
          toggle: true,
        }); // gallery
        ScrollController.ScrollAnim({
          element: "#news",
          duration: "100%",
          trigger: 0.7,
          toggle: true,
        }); // news
        ScrollController.ScrollAnim({
          element: "#community",
          duration: "100%",
          trigger: 0.7,
          toggle: true,
        }); // community
        // Scene Parallax
        const scene = document.getElementById("scene");
        if (scene) new Parallax(scene);
        // Scroll Animation Hero BLock
        gsap.timeline({ scrollTrigger: { trigger: "#hero", scrub: 0.6 } }).to(
          "#hero-floating-papers",
          {
            yPercent: "30",
            xPercent: "-30",
            rotate: "0deg",
            ease: "none",
            duration: 1,
          },
          "120%"
        );
        gsap.timeline({ scrollTrigger: { trigger: "#hero", scrub: 0.6 } }).to(
          "#hero-floating-papers2",
          {
            yPercent: "30",
            xPercent: "30",
            rotate: "0deg",
            ease: "none",
            duration: 1,
          },
          "120%"
        );
        gsap
          .timeline({ scrollTrigger: { trigger: "#hero", scrub: 1 } })
          .to(
            "#hero-floating-right-leaves",
            { yPercent: "40", ease: "none", duration: 0.4 },
            "50%"
          );
        gsap
          .timeline({ scrollTrigger: { trigger: "#hero", scrub: 1 } })
          .to(
            "#hero-floating-left-leaves",
            { yPercent: "40", ease: "none", duration: 0.4 },
            "50%"
          );
        // Scroll Animation Trailer
        gsap
          .timeline({ scrollTrigger: { trigger: "#watch-trailer", scrub: 1 } })
          .to(
            "#trailer-char",
            { xPercent: "20", ease: "none", duration: 0.4 },
            "50%"
          );
        // Scroll Animation About
        gsap
          .timeline({ scrollTrigger: { trigger: "#about", scrub: 0.7 } })
          .to("#about-sm-paperfly", {
            yPercent: "200",
            rotation: "-10",
            ease: "none",
            duration: 0.2,
          });
        gsap
          .timeline({ scrollTrigger: { trigger: "#about", scrub: 1 } })
          .to(
            "#about-md-paperfly",
            { yPercent: "70", rotation: 0, ease: "none" },
            "100%"
          );
        gsap
          .timeline({ scrollTrigger: { trigger: "#about", scrub: 1 } })
          .to(
            "#about-flowers",
            { yPercent: "80", rotation: 0, ease: "none", duration: 0.4 },
            "100%"
          );
        gsap
          .timeline({ scrollTrigger: { trigger: "#about", scrub: 1 } })
          .to(
            "#about-char-2",
            { xPercent: "-20", rotation: 0, ease: "none", duration: 1 },
            "100%"
          );
        // Scroll Animation Gallery
        gsap
          .timeline({ scrollTrigger: { trigger: "#gallery", scrub: 1 } })
          .to(
            "#gallery-paperfly",
            { yPercent: "80", rotate: "0deg", ease: "none", duration: 0.2 },
            "100%"
          );
        gsap
          .timeline({ scrollTrigger: { trigger: "#gallery", scrub: 1 } })
          .to(
            "#gallery-large-leaf",
            { yPercent: "50", rotate: "-10deg", ease: "none" },
            "100%"
          );
        gsap
          .timeline({ scrollTrigger: { trigger: "#gallery", scrub: 1 } })
          .to(
            "#gallery-small-leaf",
            { yPercent: "30", rotate: "-10deg", ease: "none" },
            "100%"
          );
        gsap
          .timeline({ scrollTrigger: { trigger: "#gallery", scrub: 1 } })
          .to(
            "#gallery-char-1",
            { xPercent: "80", ease: "none", duration: 1.2 },
            "100%"
          );
        // Scroll Animation News
        gsap
          .timeline({ scrollTrigger: { trigger: "#news", scrub: true } })
          .fromTo(
            "#news-backdrop",
            { scale: "1.1" },
            { scale: "1", ease: "none" },
            "100%"
          );
        gsap
          .timeline({ scrollTrigger: { trigger: "#news", scrub: 1 } })
          .to(
            "#news-fish-1",
            { rotate: "-20deg", ease: "none", duration: 1 },
            "100%"
          );
        gsap
          .timeline({ scrollTrigger: { trigger: "#news", scrub: 1 } })
          .to("#news-fish-2", { rotate: "-10deg", ease: "none" }, "100%");
        // Scroll Animation Community
        gsap
          .timeline({ scrollTrigger: { trigger: "#community", scrub: 1 } })
          .to(
            "#community-char-1",
            { xPercent: "-140", ease: "none", duration: 1 },
            "100%"
          );
        gsap
          .timeline({ scrollTrigger: { trigger: "#community", scrub: 1 } })
          .to("#community-char-2", { xPercent: "-32", ease: "none" });
        gsap
          .timeline({ scrollTrigger: { trigger: "#community", scrub: 1 } })
          .to("#community-overlay", {
            xPercent: "-130",
            ease: "none",
            duration: 1,
          });
        // Patern Scroll to
        const patternAbout = /about/i;
        const patternGallery = /gallery/i;
        const patternNews = /news/i;
        // Test for Scroll to on Load
        if (patternAbout.test(pathname)) {
          setTimeout(() => ScrollToView("about"), 1000);
        } else if (patternGallery.test(pathname)) {
          setTimeout(() => ScrollToView("gallery"), 1000);
        } else if (patternNews.test(pathname)) {
          setTimeout(() => ScrollToView("news"), 1000);
        } else {
          window.scrollTo(0, 0);
        }
        GLightbox({ moreLength: 0 });
      });
    };
    if (!InitEffect.current) {
      Init();
    }
    return () => {
      InitEffect.current = true;
    };
  }, [pathname]);
  return (
    <div id="app-pages">
      <div
        id="app-loader"
        className={`collapse ${translate.isReady ? "" : "show"}`}
      >
        <PreLoader />
      </div>
      <div
        id="home"
        className={`collapse fade ${translate.isReady ? "show" : ""}`}
      >
        <Header locale={pathname} path={languagePath} data={headerProps} />
        <section id="hero">
          <div className="hero-block">
            <div className="brand-logo inview animated">
              <img className="img-fluid" src={BrandLogo} alt="" />
            </div>
            <div className="wishlist-block inview animated delay-1">
              {translate.isReady && translate.home[0].acf && (
                <h2>{translate.home[0].acf.hero_title}</h2>
              )}
              <div className="d-flex align-items-center justify-content-center">
                {translate.isReady &&
                  translate.home[0].acf &&
                  translate.home[0].acf.storefront.map(
                    (store: StoreFrontMessage, index: number) => (
                      <div
                        key={index}
                        className={`icon ${sanitizeToSlug(store.name)}`}
                      >
                        {store.link ? (
                          <Link
                            to={store.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              className="img-fluid"
                              src={store.image.url}
                              alt={store.name}
                            />
                          </Link>
                        ) : (
                          <img
                            className="img-fluid"
                            src={store.image.url}
                            alt={store.name}
                          />
                        )}
                      </div>
                    )
                  )}
              </div>
            </div>
          </div>
          <div className="hero-bkg">
            {autoPlay && (
              <video
                id="hero-video"
                src={HeroTOTS}
                playsInline
                preload="auto"
                autoPlay
                muted
                loop
              />
            )}
          </div>
          <div id="hero-floating-left-leaves">
            <img
              loading="lazy"
              className="img-fluid w-100"
              src={FloatingLeftLeaves}
              width="657"
              height="1665"
              alt=""
            />
          </div>
          <div id="hero-floating-right-leaves">
            <img
              loading="lazy"
              className="img-fluid w-100"
              src={FloatingRightLeaves}
              width="943"
              height="708"
              alt=""
            />
          </div>
          <div id="hero-floating-papers">
            <img
              loading="lazy"
              className="img-fluid w-100"
              src={FloatingPapers}
              width="573"
              height="396"
              alt=""
            />
          </div>
          <div id="hero-floating-papers2">
            <img
              loading="lazy"
              className="img-fluid w-100"
              src={FloatingPapers2}
              width="528"
              height="289"
              alt=""
            />
          </div>
        </section>
        <section id="main-section">
          <div id="scene">
            <div id="scene-sky" data-depth="0.05">
              <img
                loading="lazy"
                className="img-fluid w-100"
                src={SceneSky}
                width="1920"
                height="2208"
                alt=""
              />
            </div>
            <div id="scene-bkg" data-depth="0.03" className="d-none d-lg-block">
              <img
                loading="lazy"
                className="img-fluid w-100"
                src={SceneBkg}
                width="1920"
                height="2307"
                alt=""
              />
            </div>
            <div id="scene-bkg-mobile" data-depth="0.03" className="d-lg-none">
              <img
                loading="lazy"
                className="img-fluid w-100"
                src={SceneBkgMobile}
                width="1920"
                height="3000"
                alt=""
              />
            </div>
            <div id="scene-particles" data-depth="0.13">
              <img
                loading="lazy"
                className="img-fluid"
                src={SceneParticles}
                width="1920"
                height="2307"
                alt="Tales of the Shire - Gallery"
              />
            </div>
            <div id="scene-highlight" data-depth="0.08">
              <img
                loading="lazy"
                className="img-fluid w-100"
                src={SceneHighlight}
                width="1920"
                height="2307"
                alt=""
              />
            </div>
            <div id="scene-floating" data-depth="0.06">
              <img
                loading="lazy"
                className="img-fluid w-100"
                src={SceneFloating}
                width="1920"
                height="1425"
                alt=""
              />
            </div>
          </div>
          <div id="watch-trailer">
            <div className="frame-section">
              <div className="frame-border inview animated">
                {LongTextHeader.includes(language) ? (
                  <img
                    loading="lazy"
                    className="img-fluid"
                    src={TrailerFrameLong}
                    width="1366"
                    height="875"
                    alt="Tales of the Shire - Trailer"
                  />
                ) : (
                  <img
                    loading="lazy"
                    className="img-fluid"
                    src={TrailerFrame}
                    width="1366"
                    height="875"
                    alt="Tales of the Shire - Trailer"
                  />
                )}
                <div className="frame-title d-flex align-items-center justify-content-center">
                  <h3>
                    {translate.isReady &&
                      translate.home[0].acf &&
                      translate.home[0].acf.hero_title}
                  </h3>
                </div>
                <div id="video-player">
                  <div className="ratio ratio-16x9 text-center">
                    {!showVideo && (
                      <img
                        loading="lazy"
                        className="img-fluid"
                        src={TrailerPlaceholder}
                        width="1920"
                        height="1080"
                        alt="Tales of the Shire - Trailer"
                      />
                    )}
                    {showVideo &&
                      translate.isReady &&
                      translate.home[0].acf && (
                        <iframe
                          ref={videoRef}
                          className="embed-responsive-item"
                          width="560"
                          height="315"
                          src={translate.home[0].acf.trailer_link}
                          title="Tales of the Shire - Official Announcement Trailer"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          referrerPolicy="strict-origin-when-cross-origin"
                          allowFullScreen
                        ></iframe>
                      )}
                  </div>
                  {!showVideo && (
                    <div className="play-btn-wrapper" onClick={handlePayBtn}>
                      <div className="play-btn">
                        <i className="bi bi-play-circle-fill"></i>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div id="trailer-char">
              <img
                loading="lazy"
                className="img-fluid"
                src={TrailerChar}
                width="505"
                height="948"
                alt="Tales of the Shire - Gallery"
              />
            </div>
          </div>
          <div id="about">
            <div id="about-sm-paperfly">
              <img
                loading="lazy"
                className="img-fluid"
                src={AboutSmPaperFly}
                width="197"
                height="242"
                alt="Tales of the Shire - Gallery"
              />
            </div>
            <div id="about-md-paperfly">
              <img
                loading="lazy"
                className="img-fluid"
                src={AboutMdPaperFly}
                width="381"
                height="369"
                alt="Tales of the Shire - Gallery"
              />
            </div>
            <div id="about-flowers">
              <img
                loading="lazy"
                className="img-fluid"
                src={AboutFlowers}
                width="1075"
                height="669"
                alt="Tales of the Shire - Gallery"
              />
            </div>
            <div className="frame-section">
              <div className="frame-border inview animated">
                <img
                  loading="lazy"
                  className="img-fluid"
                  src={AboutFrame}
                  width="1367"
                  height="610"
                  alt="Tales of the Shire - Community"
                />
                <div className="frame-title d-flex align-items-center justify-content-center">
                  <h3>
                    {translate.isReady &&
                      translate.home[0].acf &&
                      translate.home[0].acf.about_title}
                  </h3>
                </div>
                <div id="about-image">
                  <div className="wrapper">
                    <img
                      loading="lazy"
                      className="img-fluid"
                      src={AboutImage}
                      width="1115"
                      height="558"
                      alt="Tales of the Shire - Gallery"
                    />
                  </div>
                  <div className="scroll-content">
                    <div className="scroll-wrapper brothers">
                      {translate.isReady && translate.home[0].acf && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: translate.home[0].acf.about_story,
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div id="about-char-2">
                <img
                  loading="lazy"
                  className="img-fluid"
                  src={AboutChar2}
                  width="503"
                  height="940"
                  alt="Tales of the Shire - Gallery"
                />
              </div>
            </div>
          </div>
        </section>
        <section id="gallery">
          <div id="gallery-paperfly">
            <img
              loading="lazy"
              className="img-fluid"
              src={GalleryPaperFly}
              width="359"
              height="628"
              alt="Tales of the Shire - Gallery"
            />
          </div>
          <div id="gallery-large-leaf">
            <img
              loading="lazy"
              className="img-fluid"
              src={GalleryLargeLeaf}
              width="195"
              height="425"
              alt="Tales of the Shire - Gallery"
            />
          </div>
          <div id="gallery-small-leaf">
            <img
              loading="lazy"
              className="img-fluid"
              src={GallerySmallLeaf}
              width="298"
              height="437"
              alt="Tales of the Shire - Gallery"
            />
          </div>
          <div className="frame-section">
            <div className="frame-border inview animated">
              <div className="top-header">
                <img
                  loading="lazy"
                  className="img-fluid"
                  src={GalleryFrameTop}
                  width="1920"
                  height="155"
                  alt="Tales of the Shire - Community"
                />
              </div>
              <div className="frame-title d-flex align-items-center justify-content-center">
                <h3>
                  {translate.isReady &&
                    translate.home[0].acf &&
                    translate.home[0].acf.gallery_title}
                </h3>
              </div>
              <div id="gallery-slider">
                <SwiperComponent
                  loop
                  speed={800}
                  centeredSlides
                  initialSlide={1}
                  spaceBetween={14}
                  modules={[Navigation]}
                  navigation={{
                    prevEl: ".swiper-prev-arrow",
                    nextEl: ".swiper-next-arrow",
                  }}
                  breakpoints={{
                    0: {
                      slidesPerView: 1.6,
                    },
                    767: {
                      slidesPerView: 2.2,
                    },
                  }}
                >
                  {translate.isReady &&
                    translate.home[0].acf.gallery &&
                    translate.home[0].acf.gallery.map(function (
                      slide: any,
                      index: number
                    ) {
                      return (
                        <SwiperSlide key={index}>
                          <a
                            href={slide.url}
                            className="glightbox"
                            data-gallery="Gallery"
                            data-type="image"
                          >
                            <img
                              className="img-fluid"
                              src={slide.url}
                              alt={slide.title}
                            />
                          </a>
                        </SwiperSlide>
                      );
                    })}
                  <div className="swiper-arrow swiper-prev-arrow">
                    <img src={GalleryArrows} className="img-fluid" alt="" />
                  </div>
                  <div className="swiper-arrow swiper-next-arrow">
                    <img src={GalleryArrows} className="img-fluid" alt="" />
                  </div>
                </SwiperComponent>
              </div>
              <div className="bottom-header">
                <img
                  loading="lazy"
                  className="img-fluid"
                  src={GalleryFrameBottom}
                  width="1920"
                  height="107"
                  alt="Tales of the Shire - Community"
                />
              </div>
            </div>
          </div>
          <div id="gallery-char-1">
            <img
              loading="lazy"
              className="img-fluid"
              src={GalleryChar1}
              width="355"
              height="486"
              alt="Tales of the Shire - Gallery"
            />
          </div>
        </section>
        <section id="news">
          <div id="news-backdrop" className="h-100"></div>
          <div className="frame-section">
            <div className="frame-border inview animated">
              <div className="frame-image">
                <img
                  loading="lazy"
                  className="img-fluid d-none d-md-block"
                  src={NewsFrame}
                  width="1367"
                  height="610"
                  alt="Tales of the Shire - Community"
                />
                <img
                  loading="lazy"
                  className="img-fluid d-md-none"
                  src={NewsFrameMobile}
                  width="1366"
                  height="6000"
                  alt="Tales of the Shire - Community"
                />
              </div>
              <div className="frame-title d-flex align-items-center justify-content-center">
                <h3>
                  {translate.isReady &&
                    translate.home[0].acf &&
                    translate.home[0].acf.news_title}
                </h3>
              </div>
              <div id="news-content">
                <SwiperComponent
                  speed={800}
                  centeredSlides={translate.news.length > 1 ? false : true}
                  spaceBetween={0}
                  className={translate.news.length < 2 ? "single" : ""}
                  modules={[Navigation]}
                  navigation={{
                    prevEl: ".swiper-prev-arrow",
                    nextEl: ".swiper-next-arrow",
                  }}
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                    },
                    767: {
                      slidesPerView: 3,
                    },
                  }}
                >
                  {translate.isReady &&
                    translate.news &&
                    translate.news.map(function (slide: any, index: number) {
                      return (
                        <SwiperSlide key={index}>
                          <div className="card h-100">
                            {slide.acf.link.target ? (
                              <Link
                                to={slide.acf.link.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={slide.acf.thumb_image.url}
                                  className="card-img-top"
                                  alt={slide.title.rendered}
                                />
                              </Link>
                            ) : (
                              <Link
                                to="#"
                                onClick={() => handleOpenModal(index)}
                              >
                                <img
                                  src={slide.acf.thumb_image.url}
                                  className="card-img-top"
                                  alt={slide.title.rendered}
                                />
                              </Link>
                            )}
                            <div className="card-body brothers">
                              <h5 className="card-title">
                                {wordTrim(slide.title.rendered, 120)}
                              </h5>
                            </div>
                            <div className="card-footer d-flex align-items-center justify-content-end">
                              {slide.acf.link.target ? (
                                <Link
                                  to={slide.acf.link.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <span>{slide.acf.link.title}</span>
                                  <img
                                    loading="lazy"
                                    src={MoreArrow}
                                    className="img-fluid"
                                    alt={slide.title.rendered}
                                  />
                                </Link>
                              ) : (
                                <Link
                                  to="#"
                                  onClick={() => handleOpenModal(index)}
                                >
                                  <span>{slide.acf.link.title}</span>
                                  <img
                                    loading="lazy"
                                    src={MoreArrow}
                                    className="img-fluid"
                                    alt={slide.title.rendered}
                                  />
                                </Link>
                              )}
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  <div className="swiper-arrow swiper-prev-arrow">
                    <img src={GalleryArrows} className="img-fluid" alt="" />
                  </div>
                  <div className="swiper-arrow swiper-next-arrow">
                    <img src={GalleryArrows} className="img-fluid" alt="" />
                  </div>
                </SwiperComponent>
              </div>
            </div>
            <div className="cta-news d-none">
              <Link to="/" className="more-link">
                <SvgMoreBtn text="More News" />
              </Link>
            </div>
          </div>
          <div id="news-fish-1">
            <img
              loading="lazy"
              className="img-fluid"
              src={NewsFish1}
              width="304"
              height="225"
              alt="Tales of the Shire - News"
            />
          </div>
          <div id="news-fish-2">
            <img
              loading="lazy"
              className="img-fluid"
              src={NewsFish2}
              width="395"
              height="274"
              alt="Tales of the Shire - News"
            />
          </div>
        </section>
        <section id="community">
          <div id="community-overlay"></div>
          <div id="community-char-1">
            <img
              loading="lazy"
              className="img-fluid"
              src={CommunityChar1}
              width="227"
              height="334"
              alt="Tales of the Shire - Community"
            />
          </div>
          <div id="community-char-2">
            <img
              loading="lazy"
              className="img-fluid"
              src={CommunityChar2}
              width="396"
              height="746"
              alt="Tales of the Shire - Community"
            />
          </div>
        </section>
        <Footer locale={pathname} path={languagePath} data={footerProps} />
      </div>
      <div id="modal-block" className="collapse fade">
        <Modal size="xl" show={modalVisible} onHide={handleCloseModal}>
          <Modal.Header closeButton />
          <Modal.Body>
            {post && post.message.acf && (
              <>
                <div className="header-image">
                  <img
                    src={post.message.acf.thumb_image.url}
                    className="img-fluid"
                    alt={post.message.title.rendered}
                  />
                </div>
                <div className="article-content">
                  <h3>{post.message.title.rendered}</h3>
                  <div
                    className="content-block"
                    dangerouslySetInnerHTML={{
                      __html: post.message.content.rendered,
                    }}
                  />
                </div>
              </>
            )}
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default HomePage;
