import { Component } from "react";
import { Link } from "react-router-dom";
import { Social } from "./Social";
import { ScrollToView } from "../tools/Helpers";

import DoorLogo from "../assets/tots_door.png";
import WetaLogo from "../assets/weta_logo.svg";
import PrivateLogo from "../assets/private_division_logo.png";
import LanguageSelector from "./LanguageSelector";

interface FooterProps {
  locale: string;
  path: string;
  data: {
    footer: FooterMessage[];
    social: SocialMessage[];
    esrb: string;
    legal: string;
    isReady: boolean;
  };
}

interface FooterMessage {
  url: string;
  title: string;
  attr_title: string;
  classes: any;
  target: string;
}

interface SocialMessage {
  link: string;
  name: string;
  class: string;
}

export class Footer extends Component<FooterProps> {
  render() {
    const socialProps: { social: SocialMessage[]; isReady: boolean } = {
      social: this.props.data.social,
      isReady: this.props.data.isReady,
    };
    const currentYear = new Date().getFullYear();
    const { footer, esrb, legal, isReady } = this.props.data as {
      footer: FooterMessage[];
      esrb: string;
      legal: string;
      isReady: boolean;
    };
    const event: Event = new Event("click");
    return (
      <footer id="app-footer">
        <div className="container-xl">
          <div className="footer-brand">
            <Link
              to={this.props.path}
              onClick={() => ScrollToView("app-pages", event)}
            >
              <img
                src={DoorLogo}
                className="img-fluid"
                width="300"
                height="291"
                alt="Tales of the Shire"
              />
            </Link>
          </div>

          <div
            id="app-footer-social"
            className="d-flex align-items-center justify-content-center"
          >
            <Social data={socialProps} />
          </div>
          <div id="app-footer-language-switch">
            <LanguageSelector />
          </div>

          <div
            id="app-footer-links"
            className="d-lg-flex align-items-center justify-content-between"
          >
            <div className="footer-cell d-flex align-items-center justify-content-center justify-content-md-around">
              <div className="footer-logo">
                <Link
                  to="https://www.wetaworkshop.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={WetaLogo}
                    className="img-fluid"
                    alt="Wētā Workshop"
                  />
                </Link>
              </div>
              <div className="footer-logo">
                <Link
                  to="https://www.privatedivision.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={PrivateLogo}
                    className="img-fluid"
                    alt="Private Division"
                  />
                </Link>
              </div>
            </div>
            <div
              id="cookie"
              className="footer-links d-flex flex-wrap justify-content-center"
            >
              {isReady &&
                footer &&
                footer.map((nav: FooterMessage, index: number) =>
                  nav.classes && nav.classes[0] === "scroll" ? (
                    <Link
                      key={index}
                      className={`link rieven ${nav.classes}`}
                      to={nav.url}
                      onClick={() =>
                        ScrollToView(
                          nav.attr_title ? nav.attr_title : nav.title,
                          event
                        )
                      }
                    >
                      {nav.title}
                    </Link>
                  ) : (
                    <Link
                      key={index}
                      className={`link rieven ${nav.classes}`}
                      to={nav.url}
                      {...(nav.target
                        ? { target: "_blank", rel: "noopener noreferrer" }
                        : {})}
                    >
                      {nav.title}
                    </Link>
                  )
                )}
            </div>
            <div className="footer-cell">
              {isReady && esrb && (
                <div className="esrb">
                  <img src={esrb} className="img-fluid" alt="ESRB" />
                </div>
              )}
            </div>
          </div>
          <div className="footer-legal text-center rieven">
            <span>
              ©{currentYear} {legal}
            </span>
          </div>
        </div>
      </footer>
    );
  }
}
